import ajax from './common/ajax'

const ads = {
  filesLoaded: [],
  onLoadScriptHooks: [],
  init: function () {
    ajax('/wp-json/ads/v1/get', (data) => {
      if (!!data) {
        const ret = JSON.parse(data)
        // console.log (ret, ret.error.code)
        if (ret.error.code === 0) {
          // console.log (this);
          this.parse(ret.data)
        }
      }
    })
  },
  parse: function (pipeline) {
        // console.log (pipeline, pipeline.length);
    if (pipeline) {
      var item
      for (var i = 0; i < pipeline.length; i++) {
        item = pipeline[i]
        this.execute(item)
      }
    }
  },
  execute: function (item) {
    if (!!item.markup.selector && !!item.markup.html) {
      this.writeHtml(item.markup.html, item.markup.selector, item.markup.prepend)
    }
    if (item.js.file) {
      this.loadJsDependency(item.js.file, () => {
        if (!!item.js.execute && item.js.execute !== '') {
          this.executeJs(item.js.execute)
        }
      })
    }
  },
  loadJsDependency: function (jsFile, onLoad) {
    if (this.filesLoaded.indexOf(jsFile) === -1) {
      let script = document.createElement('script')
      this.onLoadScriptHooks[jsFile] = [onLoad]
      script.onload = () => {
        this.runScriptHooks(jsFile)
      }
      script.src = jsFile
      document.getElementsByTagName('head')[0].appendChild(script)
      this.filesLoaded.push(jsFile)
    } else {
      this.onLoadScriptHooks[jsFile].push(onLoad)
    }
  },
  runScriptHooks: function (jsFile) {
    if (this.onLoadScriptHooks[jsFile]) {
      for (let i = 0; i < this.onLoadScriptHooks[jsFile].length; i++) {
        this.onLoadScriptHooks[jsFile][i]()
      }
    }
  },
  executeJs: function (fn) {
    window[fn]()
  },
  writeHtml: function (html, selector, prepend) {
    const elements = document.querySelectorAll(selector)
    var element
    for (var i = 0; i < elements.length; i++) {
      element = elements[i]
      const newcontent = document.createElement('div')
      newcontent.innerHTML = html
      if (prepend) {
        element.insertBefore(newcontent.firstChild, element.children[0])
      } else {
        element.appendChild(newcontent.firstChild)
      }
    }
  }
}

ads.init()
